import React from 'react';
import { motion } from 'framer-motion';
import PasswordButton from '../ui/PasswordButton';

const BlockedLayout = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black z-50"
    >
      <div className="absolute inset-0">
        <video
          className="absolute inset-0 w-full h-full object-cover opacity-30"
          playsInline
          muted
          autoPlay
          loop
        >
          <source src="https://cdn.shopify.com/videos/c/o/v/fa4e94691b854e969bd35de7a8f60f4b.mp4" type="video/mp4" />
        </video>
      </div>
      
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-center mb-12"
        >
          <img
            src="https://cdn.shopify.com/s/files/1/0843/1488/2375/files/LOGO_GRAMAGE_TEXTIL.png?v=1733908299"
            alt="GramageTextil"
            className="h-16 md:h-24 mx-auto mb-8 invert"
          />
         
          <p className="text-xl md:text-2xl text-white font-light tracking-wide">
            Estamos trabajando para la mejor atención
          </p>
        </motion.div>
        
        <PasswordButton />
      </div>
    </motion.div>
  );
};

export default BlockedLayout;