import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Lock, KeyRound } from 'lucide-react';
import { usePasswordProtection } from '../../hooks/usePasswordProtection';

const PasswordButton = () => {
  const {
    password,
    setPassword,
    error,
    showError,
    validatePassword
  } = usePasswordProtection();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validatePassword();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      validatePassword();
    }
  };

  return (
    <div className="w-full max-w-md">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <KeyRound className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            className="w-full pl-10 pr-4 py-2 bg-white/20 border border-white/30 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white/50"
            placeholder="Contraseña"
          />
        </div>

        <AnimatePresence>
          {showError && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="text-red-400 text-sm text-center"
            >
              {error}
            </motion.p>
          )}
        </AnimatePresence>

        <motion.button
          type="submit"
          className="w-full bg-white/20 hover:bg-white/30 text-white py-2 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-white/50 flex items-center justify-center space-x-2"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Lock size={20} />
          <span>Acceder</span>
        </motion.button>
      </form>
    </div>
  );
};

export default PasswordButton;