import { useState } from 'react';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';

const faqs = [
  {
    question: '¿Qué tipos de tejidos ofrecen?',
    answer: 'Ofrecemos una amplia gama de tejidos para diferentes usos, desde telas decorativas hasta tejidos técnicos resistentes al agua y UV. Trabajamos con materiales naturales y sintéticos de la más alta calidad.'
  },
  {
    question: '¿Realizan envíos internacionales?',
    answer: 'Sí, realizamos envíos a nivel internacional. Los tiempos de entrega y costos varían según el destino.'
  },
  {
    question: '¿Ofrecen muestras de tejidos?',
    answer: 'Sí, podemos enviar muestras de nuestros tejidos para que puedas comprobar la calidad y el color antes de realizar tu pedido.'
  },
  {
    question: '¿Cuál es el pedido mínimo?',
    answer: 'El pedido mínimo varía según el tipo de producto y proyecto. Contáctanos para discutir tus necesidades específicas.'
  },
  {
    question: '¿Tienen servicio de diseño personalizado?',
    answer: 'Sí, ofrecemos servicios de diseño personalizado para proyectos especiales. Nuestro equipo de diseñadores puede ayudarte a crear el textil perfecto para tu proyecto.'
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <HelpCircle size={48} className="mx-auto mb-6 text-gray-900 dark:text-white" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            <span className="typing-animation">Preguntas Frecuentes</span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Encuentra respuestas a las preguntas más comunes
          </p>
        </motion.div>

        <div className="space-y-8">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">
                <span className="typing-animation">{faq.question}</span>
              </h3>

              {activeIndex === index && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.2 }}
                  className="text-gray-600 dark:text-gray-300"
                >
                  {faq.answer}
                </motion.p>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;