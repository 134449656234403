import { useState } from 'react';
import { decodePassword } from '../utils/crypto';
import { AUTH_CONSTANTS } from '../config/constants';

export const usePasswordProtection = () => {
  const [password, setPassword] = useState('');
  const [isValidated, setIsValidated] = useState(() => {
    return localStorage.getItem(AUTH_CONSTANTS.STORAGE_KEY) === 'true';
  });
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const validatePassword = () => {
    const correctPassword = decodePassword(AUTH_CONSTANTS.ENCODED_PASSWORD);
    
    if (password === correctPassword) {
      setIsValidated(true);
      localStorage.setItem(AUTH_CONSTANTS.STORAGE_KEY, 'true');
      setError('');
      setShowError(false);
      
      // Force a page reload to show the main site
      window.location.reload();
    } else {
      setError('Contraseña incorrecta. Intenta de nuevo.');
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
    setPassword('');
  };

  return {
    password,
    setPassword,
    isValidated,
    error,
    showError,
    validatePassword
  };
};