import { useTheme } from '../context/ThemeContext';

const Footer = () => {
  const { isDarkMode } = useTheme();

  return (
    <footer className={`${isDarkMode ? 'bg-black' : 'bg-gray-900'} text-white`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-12">
          {/* Logo y descripción */}
          <div className="md:col-span-4 space-y-6">
            <img
              src="https://cdn.shopify.com/s/files/1/0692/1132/4730/files/LOGO_GRAMAGE_TEXTIL.png?v=1733402877"
              alt="GramageTextil"
              className={`h-16 w-auto ${isDarkMode ? 'brightness-0 invert' : 'brightness-0 invert'}`}
            />
            <p className="text-gray-400 mt-4 text-sm leading-relaxed">
              Innovación en confección textil. Facilitamos y acompañamos la creación de proyectos textiles, poniendo nuestra industria al servicio de nuestros partners.
            </p>
          </div>

          {/* Contacto */}
          <div className="md:col-span-3 space-y-4">
            <h3 className="text-lg font-semibold text-white mb-6">
              Contacto
            </h3>
            <div className="space-y-4">
              <div className="flex items-start space-x-3 text-gray-400 hover:text-white transition-colors group">
                <span className="text-sm">Avinguda del Textil 63, CP 46830, Ontinyent, Valencia</span>
              </div>
              <div className="flex items-center space-x-3">
                <a href="tel:+34672643999" className="text-sm text-gray-400 hover:text-white transition-colors">
                  +34 672 64 39 99
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <a href="mailto:customer@sgpartners.es" className="text-sm text-gray-400 hover:text-white transition-colors">
                  customer@sgpartners.es
                </a>
              </div>
            </div>
          </div>

          {/* Enlaces legales */}
          <div className="md:col-span-3 space-y-4">
            <h3 className="text-lg font-semibold text-white mb-6">
              Legal
            </h3>
            <ul className="space-y-3">
              <li>
                <a href="/aviso-legal" className="text-sm text-gray-400 hover:text-white transition-colors">
                  Aviso Legal
                </a>
              </li>
              <li>
                <a href="/privacidad" className="text-sm text-gray-400 hover:text-white transition-colors">
                  Política de Privacidad
                </a>
              </li>
              <li>
                <a href="/cookies" className="text-sm text-gray-400 hover:text-white transition-colors">
                  Política de Cookies
                </a>
              </li>
            </ul>
          </div>

          {/* Redes sociales */}
          <div className="md:col-span-2 space-y-4">
            <h3 className="text-lg font-semibold text-white mb-6">
              Síguenos
            </h3>
            <div className="flex space-x-4">
              <a 
                href="#" 
                className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-800 rounded-full"
                aria-label="Facebook"
              >
                Facebook
              </a>
              <a 
                href="#" 
                className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-800 rounded-full"
                aria-label="Instagram"
              >
                Instagram
              </a>
              <a 
                href="#" 
                className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-800 rounded-full"
                aria-label="LinkedIn"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>

        {/* Línea divisoria */}
        <div className="border-t border-gray-800 mt-12 pt-8">
          <p className="text-center text-sm text-gray-400">
            © {new Date().getFullYear()} GramageTextil. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;