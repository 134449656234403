import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <div className="relative h-screen">
      <div className="absolute inset-0">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          playsInline
          muted
          autoPlay
          loop
        >
          <source src="https://cdn.shopify.com/videos/c/o/v/2a49fbbad4804cb58ab94829a8c3f804.mp4" type="video/mp4" />
        </video>

        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
      </div>

      <div className="relative h-full flex flex-col items-center justify-center text-center px-4 space-y-8">
        <div className="space-y-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          >
            <h1 className="story-title text-5xl md:text-7xl font-bold text-white mb-4 tracking-wide">
              GramageTextil
            </h1>
            <div className="decorative-line w-24 md:w-32 mx-auto my-6"></div>
            <p className="story-subtitle text-2xl md:text-3xl text-white font-light tracking-wider leading-relaxed">
              Donde la Innovación se Encuentra con el Arte Textil
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 1 }}
            className="space-y-4"
          >
            <div className="decorative-line w-16 md:w-20 mx-auto"></div>
            <p className="story-subtitle text-lg md:text-xl text-white/90 font-light italic tracking-wide">
              Tejiendo Historias, Innovando el Diseño
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;